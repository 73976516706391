import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom';

import * as IndividualUserConstants from './helpers/constant'
import CustomOverlayTrigger from '../widgets/overlay_trigger';

import './../../static/css/profile.css';

const QuickLinks = ({ client, userData }) => {
  return (
    <>
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'user_activities') &&
        <CustomOverlayTrigger
          content="User Activities"
          placement="bottom"
        >
          <Link
            to={client === 'clearout'
              ? `/dashboard/clearout_individual_user_activity?user_id=${userData.user_id}`
              : `/dashboard/cop_individual_user_activity?user_id=${userData.user_id}`}
            target='_blank'
          >
            <img
              src={require('../../static/img/user_activity_icon.png')}
              alt='user-activity'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'matomo_activities') &&
        <CustomOverlayTrigger
          content="Matamo Activities"
          placement="bottom"
        >
          <Link
            to={`${CLEAROUT_BASE_URL}/matomo/getvisitorprofile?user_id=${userData.user_id}&site_id=${MATOMO_SITE_ID}`}
            target='blank'
          >
            <img
              src={require('../../static/img/matamo.png')}
              alt='matamao-activity'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'email_verifier_lists') &&
        <CustomOverlayTrigger
          content="Email Verifier List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_lists?user_email=${userData.email}`}
            target='blank'
          >
            <img
              src={require('../../static/img/email_verifier.png')}
              alt='email-verifier-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'email_finder_lists') &&
        <CustomOverlayTrigger
          content="Email Finder List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_emailfinder_lists?user_email=${userData.email}`}
            target='blank'
          >
            <img
              src={require('../../static/img/email_finder.png')}
              alt='email-finder-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'prospect_lists') &&
        <CustomOverlayTrigger
          content="Prospect List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_prospect/lists?user_email=${userData.email}`}
            target='blank'
          >
            <img
              src={require('../../static/img/prospect.png')}
              alt='prospect-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'clearout_phone_lists') &&
        <CustomOverlayTrigger
          content="Clearoutphone List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/cop_lists?user_email=${userData.email}`}
            target='blank'
          >
            <img
              src={require('../../static/img/phone_number_list.png')}
              alt='clearoutphone-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
    </>
  )
}

export default QuickLinks;
