import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Breadcrumbs from '../../widgets/breadcrumb';
import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import ToggleRadarModel from './toggle_radar';
import TabsInfo from './tabs';
import LoadingWrapper from '../widgets/loading';
import * as Helpers from '../../helpers/helper'
import showToastNotification from '../widgets/toastify';
import * as IndividualUserConstants from './helpers/constant'
import * as UserInfoFormatters from './helpers/user_info_formatters'
import ActionButtons from './action_buttons';
import QuickLinks from './quick_links'
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'
import ExtraUserInfo from './extra_user_info';

import './../../static/css/loader.css'
import './../../static/css/profile.css'
import 'react-toggle/style.css'

const initialState = {
  userData: null,
  isRadarModalOpen: false,
  isChecked: true,
  additionalUserData: {},
  isLoading: false,
}

const ClearoutIndividualUserInfo = ({ client, userId }) => {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id') || userId;

  const getClearoutUserInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].USER_INFO.API_BODY)

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].USER_INFO.API_NAME](body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          // console.log(response.data)
          setState(prevState => ({ ...prevState, userData: response.data, }))
          {
            client === 'clearout' ? (
              setState(prevState => ({ ...prevState, isChecked: response.data.radar.active }))
            ) : null
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [user_id, client])

  const getClearoutUserAdditionalInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      cop_user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].ADDITIONAL_INFO.API_BODY)

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].ADDITIONAL_INFO.API_NAME](body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          // console.log(response.data)
          setState(prevState => ({ ...prevState, additionalUserData: response.data }))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [user_id, client])

  const getUserInfo = useCallback(() => {
    getClearoutUserInfo()
    getClearoutUserAdditionalInfo()
  }, [getClearoutUserInfo, getClearoutUserAdditionalInfo])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const handletoggleModal = () => {
    setState(prevState => ({ ...prevState, isRadarModalOpen: false }))
  };

  const handleToggleChange = () => {
    let requestBody = {
      co_user_id: user_id,
      user_id: id,
      reason: state.reason,
      radarStatus: true
    };
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].RADAR.API_BODY)
    // Toggle the isChecked state
    setState(prevState => ({ ...prevState, isChecked: !state.isChecked }))

    // If the toggle is deactivated, open the modal
    if (state.isChecked) {
      setState(prevState => ({ ...prevState, isRadarModalOpen: true }));
    } else {
      handleToggleRadarApiCall(body);
    }
  };

  const handleToggleRadarApiCall = (body) => {
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].RADAR.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'failed',
            isSuccess: false,
          });
        } else (response.status === Constants.CO_API.SUCCESS); {
          showToastNotification({
            message: 'Radar settings updated successfully',
            isSuccess: true,
          });
          handletoggleModal();
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  //data formatting 
  const getIconColor = (data) => {
    if (data.account_status === 'removed') {
      return '#ff6767'
    } else if (!data.activated_on || data.activated_on === 'Yet to Activate') {
      return '#a3a3a3'
    } else if (data.status === 'active') {
      return '#6229e3'
    } else {
      return '#e48725'
    }
  }

  const getLeftTable = () => {
    const leftTable = IndividualUserConstants.BASIC_USER_INFO[client].LEFT_TABLE

    return (
      <>
        {leftTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex align-items-center flex-wrap'
            >
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getRightTable = () => {
    const rightTable = IndividualUserConstants.BASIC_USER_INFO[client].RIGHT_TABLE

    return (
      <>
        {rightTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex align-items-center'
            >
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getBadges = () => {
    let tagsToShow = []

    // Handle both string and object types in the tags array
    state.userData.tags.forEach(tag => {
      if (typeof tag === 'string') {
        tagsToShow.push(tag)
      } else if (typeof tag === 'object' && tag.type) {
        tagsToShow.push(tag.type)
      }
    })

    let userRole = state.userData.user_role
    if (userRole === 'analyst')
      userRole = 'executive'

    tagsToShow.push(userRole)

    let backgrounds = ['primary', 'success', 'danger', 'warning', 'info']

    return (
      <div className='d-flex gap-1'>
        {
          tagsToShow.map(tag => {
            return (
              <Badge
                key={tag}
                bg={backgrounds[_.random(0, backgrounds.length - 1)]}
              >
                {Helpers.capitalizeFirstLetter(tag)}
              </Badge>
            )
          })
        }
      </div>
    )
  }


  if (!state.userData) {
    return <p>No Data</p>
  }

  return (
    <div className='container-fluid overflow-auto'>
      {state.isLoading ? (
        <div className="loader-container">
          <LoadingWrapper
            type="bars"
            color="#007bff"
            height={50}
            width={50}
          />
        </div>
      ) : null}
      <div
        className='container-fluid'
      >
        <Breadcrumbs options={Constants.BREAD_CRUMB_OPTIONS[client]} />
        <div className="info-section-container">
          <div className="basic-info-section mb-3 p-3">
            <div className='d-flex justify-content-center pt-2'>
              <h3
                className='text-center'
                key={state.userData.status}
              >
                <i
                  className={state.userData.user_type === 'organization' ? 'fa fa-users' : 'fa fa-user'}
                  style={{ color: getIconColor(state.userData) }}
                />
                <a
                  style={{ color: getIconColor(state.userData) }}
                >
                  &nbsp;&nbsp;{state.userData.name ? Helpers.capitalizeFirstLetter(state.userData.name) : state.userData.email}&nbsp;
                </a>
              </h3>
            </div>
            <div className="d-flex justify-content-center">
              {getBadges()}
            </div>
            <div className="d-flex justify-content-end gap-3 py-2">
              <QuickLinks
                client={client}
                userId={userId}
                userData={state.userData}
              />
            </div>
            <div className="d-flex user-info pt-2">
              <div className="col-md-5 p-0">
                {getLeftTable()}
              </div>
              <div className="col-md-4 p-0">
                {getRightTable()}
              </div>
              <div className="col-md-3 p-0">
                <ActionButtons
                  client={client}
                  userData={state.userData}
                  getUserInfo={getUserInfo}
                />
              </div>
            </div>
          </div>
          <div className='px-3 extra-info-section'>
            <ExtraUserInfo
              userData={state.userData}
              client={client}
              isChecked={state.isChecked}
              handleToggleChange={handleToggleChange}
              additionalData={state.additionalUserData}
              getUserInfo={getUserInfo}
            />
          </div>
        </div>
      </div>
      {/* tab section */}
      <div className='p-3'>
        <TabsInfo
          userData={state.userData}
          getUserInfo={getUserInfo}
          client={client}
        />
      </div>
      <ToggleRadarModel
        show={state.isRadarModalOpen}
        onHide={handletoggleModal}
        co_user_id={user_id}
        name={state.userData.name}
        handleToggleRadar={handleToggleRadarApiCall}
        getUserInfo={getUserInfo}
      />
    </div>
  )
}
export default ClearoutIndividualUserInfo
